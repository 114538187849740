import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {PrivateRoute} from "./components/PrivateRoute";
import {Role} from "./helpers/role";
import Dashboard from "./components/Dashboard";
import DashboardAnalytics from "./components/DashboardAnalytics";
import TemplateGroup from "./components/TemplateGroup";
import Template from "./components/Template";
import Rule from "./components/Rule";
import Deployment from "./components/Deployment";
import CoreTranslation from "./components/CoreTranslation";
import ProfileTranslation from "./components/ProfileTranslation";
import Department from "./components/Department";
import Section from "./components/Section";
import User from "./components/User";
import CoreSettings from "./components/CoreSettings";
import ProfileSettings from "./components/ProfileSettings";
import UserSettings from "./components/UserSettings";

const AppRoute = () => {

    return (
        <div className="layout-content">
            <PrivateRoute roles={[Role.ROLE_USER]} path={["/", "/home"]} exact component={Dashboard}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/dashboard/analytics" component={DashboardAnalytics}/>

            <PrivateRoute roles={[Role.ROLE_USER]} path="/departments" component={Department}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/sections" component={Section}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/users" component={User}/>

            <PrivateRoute roles={[Role.ROLE_USER]} path="/templates" exact component={Template}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/templates/groups" exact component={TemplateGroup}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/rules" exact component={Rule}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/deployments" exact component={Deployment}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/translations/core" exact component={CoreTranslation}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/translations/profile" exact component={ProfileTranslation}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/core" exact component={CoreSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/profile" exact component={ProfileSettings}/>
            <PrivateRoute roles={[Role.ROLE_USER]} path="/settings/user" exact component={UserSettings}/>
        </div>
    );

}

export default AppRoute;
