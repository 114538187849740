import React, {Component} from 'react';
import {connect} from "react-redux";

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {clearMessage} from "./actions/message";

import {history} from './helpers/history';
import AppDefault from "./AppDefault";
import {I18n} from "react-redux-i18n";
import {Role} from "./helpers/role";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentAccount: undefined,
            menu: [],
        };

        history.listen(() => {
            props.dispatch(clearMessage()) // clear message when changing location
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createMenu();
        }
    }

    componentDidMount() {
        this.createMenu();
        //this.props.dispatch(connectSocket(Constant.WS_API_BASE + '/api/v1/core/ws', [], Constant.WS_PREFIX));
        //this.props.dispatch(connectSocket(Constant.WS_API_BASE + '/api/v1/core/chat', [], Constant.WS_CHAT_PREFIX));
        const account = this.props.account;
        if (account) {
            this.setState({
                currentAccount: account,
            });
        }
    }

    createMenu() {
        const menu = []

        //first group
        let items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_USER)) {
            items.push({label: I18n.t('DASHBOARD_STATS'), icon: 'pi pi-fw pi-home', to: '/', badge: '4', badgeClassName: 'p-badge-info'})
            items.push({label: I18n.t('DASHBOARD_ANALYTICS'), icon: 'pi pi-fw pi-home', to: '/dashboard/analytics', badge: '2', badgeClassName: 'p-badge-success'})
        }
        menu.push({label: I18n.t('FAVORITES'), icon: 'pi pi-fw pi-home', items: items})

        //second group
        items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_USER)) {
            items.push({label: I18n.t('DEPARTMENTS'), to: '/departments'})
            items.push({label: I18n.t('SECTIONS'), to: '/sections'})
            items.push({label: I18n.t('ACCOUNTS'), to: '/users'})
        }
        menu.push({label: I18n.t('OTHERS'), icon: 'pi pi-fw pi-star', items: items})

        items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN)) {
            items.push({label: I18n.t('DEPLOYMENTS'), to: '/deployments'})
        }
        menu.push({label: I18n.t('BUSINESS_PROCESS_ENGINE'), icon: 'pi pi-fw pi-star', items: items})

        items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN)) {
            items.push({label: I18n.t('RULES'), to: '/rules'})
        }
        menu.push({label: I18n.t('BUSINESS_RULE_ENGINE'), icon: 'pi pi-fw pi-star', items: items})

        items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN)) {
            items.push({label: I18n.t('TEMPLATE_GROUPS'), to: '/templates/groups'})
            items.push({label: I18n.t('TEMPLATES'), to: '/templates'})
        }
        menu.push({label: I18n.t('NOTIFICATIONS'), icon: 'pi pi-fw pi-star', items: items})

        items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN)) {
            items.push({label: I18n.t('CORE_TRANSLATIONS'), to: '/translations/core'})
            items.push({label: I18n.t('PROFILE_TRANSLATIONS'), to: '/translations/profile'})
        }
        menu.push({label: I18n.t('TRANSLATIONS'), icon: 'pi pi-fw pi-star', items: items})

        items = []
        if (this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN)) {
            items.push({label: I18n.t('CORE_SETTINGS'), to: '/settings/core'})
            items.push({label: I18n.t('PROFILE_SETTINGS'), to: '/settings/profile'})
            items.push({label: I18n.t('USER_SETTINGS'), to: '/settings/user'})
        }
        menu.push({label: I18n.t('SETTINGS'), icon: 'pi pi-fw pi-star', items: items})


        this.setState(({
            menu: menu,
        }));
    }

    render() {
        return (
            <AppDefault menu={this.state.menu}/>
        );
    }
}

function mapStateToProps(state) {
    const {account} = state.auth;
    const locale = state.i18n.locale;

    return {
        account,
        locale
    };
}

export default connect(mapStateToProps)(App);

