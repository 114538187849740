import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Redirect} from 'react-router-dom';
import {I18n} from "react-redux-i18n";
import {Messages} from 'primereact/messages';
import {connect} from "react-redux";
import {Constant} from "../helpers/const";
import {login} from "../actions/auth";

class Login extends Component {

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.showResponse = this.showResponse.bind(this);

        this.state = {
            username: "",
            password: "",
            loading: false,
            recaptcha: false,
            recaptchaResponse: null,
        };
    }

    showResponse(response) {
        if (response) {
            this.setState({
                recaptchaResponse: response.response,
            });
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.state.recaptcha === true && this.state.recaptchaResponse === null) {
            this.msgs.show([
                {severity: 'error', detail: I18n.t(Constant.RECAPTCHA_NOT_SET), sticky: false}
            ]);
            return
        }

        this.setState({
            loading: true,
        });
        const {dispatch, history} = this.props;
        dispatch(login(this.state.username, this.state.password, this.state.recaptchaResponse))
            .then((objectLogin) => {
                console.log("loginnnnnnnn ", objectLogin)
                if (objectLogin.code === "ACTIVATION_CODE_SUCCESS") {
                    history.push(`/password/${this.state.username}/${this.state.password}`)
                } else {
                    //to refresh  session data
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log("loginnnnnnnn error", error)
                this.setState({
                    loading: false,
                });

                if (this.props.message.code === "REQUIRES_CAPTCHA_ERROR") {
                    this.setState({
                        recaptcha: true,
                    });
                }

                this.msgs.show([
                    {severity: 'error', detail: I18n.t(this.props.message.code), sticky: true}
                ]);
            });
    }

    render() {
        const {isLoggedIn} = this.props;
        if (isLoggedIn) {
            return <Redirect to="/"/>;
        }
        const {history} = this.props;

        const goHome = () => {
            history.push('/');
        }

        return (
            <div className="pages-body login-page p-d-flex p-flex-column">
                <div className="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
                    <div className="topbar-left p-ml-3 p-d-flex">
                        <div className="logo">
                            <img src="assets/layout/images/logo2x.png" alt=""/>
                        </div>
                    </div>
                    <div className="topbar-right p-mr-3 p-d-flex">
                        <Button onClick={goHome} type="button" label={I18n.t('HOME')}
                                className="p-button-text p-button-plain topbar-button"></Button>
                    </div>
                </div>

                <div className="p-as-center p-mt-auto p-mb-auto" style={{width: '400px'}}>
                    <div className="pages-panel card p-d-flex p-flex-column">
                        <div className="pages-header p-px-3 p-py-1">
                            <h2>{I18n.t('LOGIN')}</h2>
                        </div>

                        <h4>{I18n.t('WELCOME')}</h4>

                        <div className="pages-detail p-mb-6 p-px-6">{I18n.t('SIGN_IN_MESSAGE')}</div>

                        <div className="input-panel p-d-flex p-flex-column p-px-3">
                            <Messages ref={(el) => this.msgs = el}/>
                            <form style={{width: '100%'}}
                                  onSubmit={this.handleLogin}
                                  ref={(c) => {
                                      this.form = c;
                                  }}>
                                <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                    <span className="p-float-label">
                                    <InputText
                                        required
                                        type="email"
                                        name="username"
                                        value={this.state.username}
                                        onChange={this.onChangeUsername}
                                        id="inputgroup1"/>
                                    <label htmlFor="inputgroup1">{I18n.t('EMAIL')}</label>
							    </span>
                                </div>

                                <div className="p-inputgroup p-mt-3 p-mb-6">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                    <span className="p-float-label">
                                    <InputText
                                        required
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChangePassword}
                                        id="inputgroup2"/>
                                    <label htmlFor="inputgroup2">{I18n.t('PASSWORD')}</label>
							    </span>
                                </div>

                                <Button loading={this.state.loading} style={{width: '100%'}} className="login-button p-mb-6 p-px-3" label={I18n.t('LOGIN')}></Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const message = state.message;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(Login);
